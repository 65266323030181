"use strict";

require(["jquery", "jquery.idletimeout", "jquery.idletimer"], function ($) {
    var timeout = {
        init: function init() {
            this.cache();
            this.bindEvents();
        },
        cache: function cache() {
            this.containerId = '#idletimeout';
            this.resumeBtnId = '#idletimeout-resume';
            this.logoutBtnId = '#idletimeout-logout';
            this.countdownId = '#idletimeout-counter';
            this.$container = $(this.containerId);
            this.keepAlivePath = this.$container.find(this.resumeBtnId).data('keepalive-path');
        },
        bindEvents: function bindEvents() {
            var self = this;

            if (self.$container.length) {
                $.idleTimeout(self.containerId, self.resumeBtnId, {
                    idleAfter: 1140,
                    pollingInterval: 1500,
                    keepAliveURL: $("#idletimeout").find($("#idletimeout-resume")).data('keepalive-path'),
                    serverResponseEquals: 'OK',
                    warningLength: 60,
                    sessionKeepAliveTimer: false,
                    onTimeout: function onTimeout() {
                        location.href = $("#idletimeout").find($("#idletimeout-logout")).attr('href');
                    },
                    onIdle: function onIdle() {
                        $("#idletimeout").fadeIn();
                    },
                    onCountdown: function onCountdown(counter) {
                        $("#idletimeout").find($("#idletimeout-counter")).html(counter);
                    },
                    onResume: function onResume() {
                        $.get($("#idletimeout").find($("#idletimeout-resume")).data('keepalive-path'));
                        $("#idletimeout").fadeOut();
                    }
                });
            }
        }
    };

    timeout.init();
});